import { api } from "./configs/axiosConfig";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const UsageType = {
  ALL: "all",
  CHAT: "chat",
  IMAGE: "image",
};

export const UsageAPI = {
  
  usage: async function (modelType, startDate, endDate, cancel = false) {

    const requestOptions = {};
    startDate && (requestOptions.start_date = startDate);
    endDate && (requestOptions.end_date = endDate);

    try {
      const response = await api.request({
        url: `/admin/usage/${modelType}`,
        method: "POST",
        data: requestOptions,
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },

  /*
  {{base_url}}/admin/usage/model

   {
      "date": "2025-01-29",
      "token_usage": {
        "anthropic.claude-3-5-sonnet-20240620-v1:0": {
          "input_tokens": 20,
          "output_tokens": 180
        },
        "anthropic.claude-3-haiku-20240307-v1:0": {
          "input_tokens": 212,
          "output_tokens": 124
        }
      },
      "image_gen": {
        "stability.stable-diffusion-xl-v1": 1,
        "stability.sd3-large-v1:0": 2
      }
    },

  */
  tokenUsage: async function (startDate, endDate, userDetail = false, cancel = false) {

    const requestOptions = { user_detail: userDetail };
    startDate && (requestOptions.start_date = startDate);
    endDate && (requestOptions.end_date = endDate);

    try {
      const response = await api.request({
        url: `/admin/usage/model`,
        method: "POST",
        data: requestOptions,
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },

  /*
  {{base_url}}/admin/prompt_reason/all
  {{base_url}}/admin/prompt_reason/chat
  {{base_url}}/admin/prompt_reason/image

  [
    {
      "prompt_reason": "XSS payload testing",
      "count": 16,
      "total_min_saved": 105
    },
    {
      "prompt_reason": "SKU volume report request",
      "count": 14,
      "total_min_saved": 375
    },
    {
      "prompt_reason": "XSS attack attempt",
      "count": 12,
      "total_min_saved": 0
    },
    {
      "prompt_reason": "Asking AI's name",
      "count": 11,
      "total_min_saved": 0
    }
  ]

  */
  promptReasons: async function (modelType, startDate, endDate, cancel = false) {

    const requestOptions = { };
    startDate && (requestOptions.start_date = startDate);
    endDate && (requestOptions.end_date = endDate);

    try {
      const response = await api.request({
        url: `/admin/prompt_reason/${modelType}`,
        method: "POST",
        data: requestOptions,
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },

  /*
  {{base_url}}/admin/work_activity/all
  {{base_url}}/admin/work_activity/chat
  {{base_url}}/admin/work_activity/image

  [
    {
      "work_activity_count": 3,
      "total_min_saved": 21,
      "prompt_reasons": [
        {
          "prompt_reason": "Brevity instruction",
          "count": 1
        },
        {
          "prompt_reason": "Summarize introduction email",
          "count": 1
        },
        {
          "prompt_reason": "Rephrase email message",
          "count": 1
        }
      ],
      "work_activity": "Content Editing"
    },
    {
      "work_activity_count": 19,
      "total_min_saved": 134,
      "prompt_reasons": [
          {
            "prompt_reason": "NFL team knowledge",
            "count": 1
          },
          {
            "prompt_reason": "Entertainment news update",
            "count": 1
          },
          {
            "prompt_reason": "Sales data inquiry",
            "count": 1
          },
          {
            "prompt_reason": "Generate random names",
            "count": 1
          },
          {
            "prompt_reason": "Request additional river names",
            "count": 1
          },
          {
            "prompt_reason": "News update request",
            "count": 1
          },
        ]
      }
    ]
  */
  workActivity: async function (modelType, startDate, endDate, cancel = false) {

    const requestOptions = { };
    startDate && (requestOptions.start_date = startDate);
    endDate && (requestOptions.end_date = endDate);

    try {
      const response = await api.request({
        url: `/admin/work_activity/${modelType}`,
        method: "POST",
        data: requestOptions,
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },
  
  groups: async function(search = "", limitToPreset = false, cancel = false) {
    const requestOptions = {
      search: search,
      limit_to_preset: limitToPreset,
    };

    try {
      const response = await api.request({
        url: "/admin/groups",
        method: "POST",
        data: requestOptions,
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },

  groupUsage: async function(usageType, groupId, startDate, endDate, cancel = false) {
    const requestOptions = {};
    startDate && (requestOptions.start_date = startDate);
    endDate && (requestOptions.end_date = endDate);
    groupId && (requestOptions.group_id = groupId);

    try {
      const response = await api.request({
        url: `/admin/usage/group/${usageType}`,
        method: "POST",
        data: requestOptions,
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },

};


// defining the cancel API object for UsageAPI
const cancelApiObject = defineCancelApiObject(UsageAPI)