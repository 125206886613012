import React, { useContext, useState } from 'react';
import { TextConfigContext } from '../../context/textConfigContext';
import Switch from "react-switch"; // Doc: https://www.npmjs.com/package/react-switch
import info from '../../assets/info.png';



/**
    temperature: float = 0.5, 0 - 1 range, .1 increments
    top_p: float = 1.0, 0 - 1 range, .001 increments
    top_k: int = 250, 0 - 500 range
    max_tokens: int = 2000, 0 - 2048 range
    stop_sequences: list[str] = ["\n\nHuman:"]
    model: str = "anthropic.claude-v2"
 */

const TextConfig = () => {

  const [getTextConfig, setTextConfig, template, setTemplate, , , , , isBingSearchEnabled, setIsBingSearchEnabled ] = useContext(TextConfigContext);
  const [newSequence, setNewSequence] = useState('');
  const [infoBlockOpen, setInfoBlockOpen] = useState(null);

  const handleInfoClick = (infoBlockNumber) => {
    setInfoBlockOpen(infoBlockNumber === infoBlockOpen ? null : infoBlockNumber);
  }

  const handleTemperatureChange = (e) => {
    setTextConfig({temperature: e.target.value}, "temperature");
  };

  const handleTopPChange = (e) => {
    setTextConfig({topP: e.target.value}, "topP");
  }

  const handleTopKChange = (e) => {
    setTextConfig({topK: parseInt(e.target.value)}, "topK");
  }

  const handleMaxTokensChange = (e) => {
    setTextConfig({maxTokens: parseInt(e.target.value)}, "maxTokens");
  }

  const handleNewSequenceChange = (e) => {
    setNewSequence(e.target.value);
  };

  const handleAddWord = () => {
    const updatedWords = [...getTextConfig().stopSequences, newSequence];
    setTextConfig({stopSequences: updatedWords}, "stopSequences");
    setNewSequence('');
  };

  const handleRemoveWord = (index) => {
    const updatedWords = [...getTextConfig().stopSequences];
    updatedWords.splice(index, 1);
    setTextConfig({stopSequences: updatedWords}, "stopSequences");
  };

  const handleTemplateChange = (e) => {
    setTemplate(e.target.value);
  };

  const handleBingSearchSwitchChanged = (e) => {
    var isChecked = e;
    setIsBingSearchEnabled(isChecked);
  }

  return (
    <div className="bg-gray-200 min-h-screen p-4">
      <h2 className="font-bold text-2xl mb-4">Model Configurations</h2>
      <p className="text-base mb-6">Adjust the model configurations to control the responsiveness of Skechers GPT and customize your chat experience.</p>
      <form>
        <h2 className="text-xl font-bold mt-8 mb-2 mr-0 flex item-center">
          Randomness and Diversity
          <img src={info} alt="Information" className="ml-2 mt-2 w-4 h-4 pointer-cursor" onClick={() => { handleInfoClick(1); }}/>
        </h2>
        <div className="relative flex flex-row grow">
          <div className={`absolute bg-slate-300 h-fit flex flex-col grow mh-2 p-4 z-10 ${infoBlockOpen !== 1 && "hidden"}`}>
            <h3 className="text-s font-bold">Temperature:</h3>
            <p className="my-2">Use a lower value to decrease randomness / creativity in the response.</p>
            <h3 className="text-s font-bold">Top P:</h3>
            <p className="my-2">Use a lower value to ignore less probable options.</p>
            <h3 className="text-s font-bold">Top K:</h3>
            <p className="my-2">Specifies the number of token choices the model uses to generate the next token. Adjusts how closely the AI sticks to context. Lower values allow more random connections. Higher values make the AI stay closely on topic.</p>
          </div>
        </div>
        <div className="container font-bold">
          <label className='flex items-center'>
            Temperature
            <input type="number" min="0.0" max="1.0" step="0.1" value={getTextConfig().temperature} onChange={(handleTemperatureChange)}className="px-2 py-2 text-center w-20 h-8 ml-auto border-solid border-2 border-blue-900"/>
          </label>  
          <input type="range" min="0.0" max="1.0" step="0.1" value={getTextConfig().temperature} onChange={handleTemperatureChange} className="range range-xs range-config mt-4 w-full bg-gray-300" />
        </div>
        
        <div className="container mt-4 font-bold">
          <label className='flex items-center'>
            Top P
            <input type="number" min="0.0" max="1.0" step="0.001" value={getTextConfig().top_p} onChange={(handleTopPChange)} className="px-2 py-2 text-center w-20 h-8 ml-auto border-solid border-2 border-blue-900"/>
          </label>  
          <input type="range" min="0.0" max="1.0" step="0.001" value={getTextConfig().top_p} onChange={handleTopPChange} className="range range-xs range-config mt-4 w-full bg-gray-300" />
        </div>

        <div className="container mt-4 font-bold">
          <label className='flex items-center'>
            Top K
            <input type="number" min="0" max="500" step="1" value={getTextConfig().top_k} onChange={(handleTopKChange)} className="px-2 py-2 text-center w-20 h-8 ml-auto border-solid border-2 border-blue-900"/>
          </label>  
          <input type="range" min="0" max="500" step="1" value={getTextConfig().top_k} onChange={handleTopKChange} className="range range-xs range-config mt-4 w-full bg-gray-300" />
        </div>

        <div className="container mt-12">
          <h2 className="text-xl font-bold mb-2 mr-0 flex item-center">
            Length 
            <img src={info} alt="Information" className="ml-2 mt-2 w-4 h-4" onClick={() => { handleInfoClick(2); }}/>
          </h2>
          <div className="relative flex flex-row grow">
            <div className={`absolute bg-slate-300 h-fit flex flex-col grow mh-2 p-4 z-10 ${infoBlockOpen !== 2 && "hidden"}`}>
              <h3 className="text-s font-bold">Maximum Length:</h3>
              <p className="my-2">Maximum number of tokens to use in the generated response. 100 tokens is roughly 75 words.</p>
              <h3 className="text-s font-bold">Stop Sequences:</h3>
              <p className="my-2">You can set up to four sequences that the model recognizes. After a stop sequence, the model stops generating further tokens. The returned text doesn't contain the stop sequence.</p>
            </div>
          </div>
          <label className='flex items-center font-bold'>
            Maximum Length
            <input type="number" min="0" max="4096" step="1" value={getTextConfig().max_tokens} onChange={(handleMaxTokensChange)} className="px-2 py-2 text-center w-20 h-8 ml-auto border-solid border-2 border-blue-900"/>
          </label>
          <input type="range" min="0" max="4096" step="1" value={getTextConfig().max_tokens} onChange={handleMaxTokensChange} className="range range-xs range-config mt-4 w-full bg-gray-300" />
        </div>

        <div className="container mt-4">
        <label className="font-bold">
          Stop Sequences
          <input
            type="text"
            value={newSequence}
            onChange={handleNewSequenceChange}
            className="flex-grow px-3 py-2 mt-4 border border-gray-300 w-48 h-12 text-gray-900 border-solid border-2 border-blue-900"/>
        </label>  
        <button type="button" onClick={handleAddWord}className="px-4 py-2 h-12 bg-blue-900 text-white">Add</button>
        <div className="mt-4">
          {getTextConfig().stop_sequences.map((word, index) => (
            <div key={index} className="flex items-center mt-2">
              <div className="flex items-center bg-blue-300 w-auto h-10 text-bold border-solid border-2 border-blue-900 px-3 py-2 mr-2">
                <span>{word}</span>
                <button type="button" onClick={() => handleRemoveWord(index)} className="ml-2 px-2 py-1 h-8 bg-blue-300 text-white text-blue-900 rounded-md">
                  X
                </button>
              </div>
            </div>
          ))}
        </div>
        <div className={`container mt-12 ${template ? "" : "hidden"}`}>
          <h2 className="text-xl font-bold mb-2 mr-0 flex item-center">
            Pre-Prompt Template 
            <img src={info} alt="Information" className="ml-2 mt-2 w-4 h-4" onClick={() => { handleInfoClick(3); }}/>
          </h2>
          <div className="relative flex flex-row grow">
            <div className={`absolute bg-slate-300 h-fit flex flex-col grow mh-2 p-4 z-10 ${infoBlockOpen !== 3 && "hidden"}`}>
              <p className="my-2">
                <em>The pre-prompt is only sent on the first interaction. If you edit it after the first interaction it will have no effect. If you want to edit the pre-prompt, do it before sending your first query.</em><br/>
                <br/>The pre-prompt are detailed pre-configured instructions sent to the model. The format is important; the template must start with 2 blank lines and then "Human:". 
                <br/>It can include {`${ "{{PROMPT}}" }`} to indicate where the user's input should be inserted.
              </p>
            </div>
          </div>
          <textarea value={template} rows="10" onChange={(handleTemplateChange)} className="px-2 py-2 w-full border-solid border-2 border-blue-900"/>
        </div>

        <div className="container mt-12">
          <h2 className="text-xl font-bold mb-2 mr-0 flex item-center">
            Capabilities
            <img src={info} alt="Information" className="ml-2 mt-2 w-4 h-4" onClick={() => { handleInfoClick(4); }}/>
          </h2>
          <div className="relative flex flex-row grow">
            <div className={`absolute bg-slate-300 h-fit flex flex-col grow mh-2 p-4 z-10 ${infoBlockOpen !== 4 && "hidden"}`}>
              <h3 className="text-s font-bold">Bing Search:</h3>
              <p className="my-2">Enables searching the Web using Bing.</p>
            </div>
          </div>
          <div className='container mb-4'>
            <label className="flex justify-between font-bold">
              Bing Search API
              <Switch onChange={handleBingSearchSwitchChanged} checked={isBingSearchEnabled} />
            </label>
          </div>

        </div>
      </div>
    </form>
    </div>
  );
};

export default TextConfig;
