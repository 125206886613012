import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import rehypeExternalLinks from 'rehype-external-links';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';
import ImageResponse from '../../Image';
import bot from '../../../assets/avatar_bot.png';
import human from '../../../assets/avatar_human.png';
import thumbsUp from '../../../assets/thumbs_up.png';
import thumbsDown from '../../../assets/thumbs_down.png';
import regenerate from '../../../assets/regenerate.png';
import copy from '../../../assets/copy.png';
import { FaRegBookmark } from "react-icons/fa";

const ChatMessageMobile = (props) => {
  const { id, text, total_tokens, ai, error, feedback, sendFeedback, handleCopyToClipboard, handleEnterSavePrompt, textToCopyRef, modelType, onRegenerate } = props;

  const isImageResponse = modelType === 'image' && ai;

  return (
    <div key={id} className="message w-full px-4 py-2">
      <div className={`message__mobile message__wrapper__mobile`} ref={textToCopyRef}>
        {error ? (
          <div className='bg-red-100 p-2 rounded text-red-900'>
            {text}
          </div>
        ) : isImageResponse ? (
          <div>
            <ImageResponse raw={text} key={id} />
          </div>
        ) : (
          <ReactMarkdown
            className={`message__markdown__mobile`}
            children={typeof text === 'string' ? text : ''}
            remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
            rehypePlugins={[rehypeRaw, rehypeSanitize, [rehypeExternalLinks, { target: '_blank', rel: 'noreferrer noopener' }]]}
            components={{
              code({ node, inline, className, children, ...props }) {
                const match = /language-(\w+)/.exec(className || '')
                return !inline && match ? (
                  <SyntaxHighlighter
                    children={String(children).replace(/\n$/, '')}
                    style={oneDark}
                    language={match[1]}
                    PreTag="div"
                    {...props}
                  />
                ) : (
                  <code className={className} {...props}>
                    {children}
                  </code>
                )
              }
            }}
          />
        )}

        {ai && !isImageResponse && (
          <div className="token__usage__mobile">
            <small>Token usage: {total_tokens?.toLocaleString()} / 200,000</small>
          </div>
        )}

        <div className={`message__actions__mobile`}>
          <div className='flex items-center'>
            <div className="message__avatar__mobile">
              <img src={ai ? bot : human} alt={ai ? 'bot pic' : 'profile pic'} className="message__avatar__image__mobile" />
            </div>
          </div>
          <div className='flex items-center space-x-2'>
            {ai ? (
              <>
                <button 
                  className={`message__action__button__mobile ${feedback === 1 ? 'bg-green-200' : ''}`}
                  onClick={() => sendFeedback(id, feedback === 1 ? 0 : 1)}
                >
                  <img src={thumbsUp} alt='thumbs up' className="message__action__icon__mobile" />
                </button>
                <button 
                  className={`message__action__button__mobile ${feedback === -1 ? 'bg-red-200' : ''}`}
                  onClick={() => sendFeedback(id, feedback === -1 ? 0 : -1)}
                >
                  <img src={thumbsDown} alt='thumbs down' className="message__action__icon__mobile" />
                </button>
                {!isImageResponse && (
                  <button className='message__action__button__mobile' onClick={handleCopyToClipboard}>
                    <img src={copy} alt='copy' className="message__action__icon__mobile" />
                  </button>
                )}
                <button className='message__action__button__mobile' onClick={onRegenerate}>
                  <img src={regenerate} alt='regenerate' className="message__action__icon__mobile" />
                </button>
              </>
            ) : null}
            {!isImageResponse && (
              <button className='message__action__button__mobile' onClick={handleEnterSavePrompt}>
                <FaRegBookmark className="message__action__icon__mobile" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatMessageMobile;
