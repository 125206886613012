import React, { useContext, useState } from 'react';
import { ImageConfigContext } from '../../context/imageConfigContext';
import info from '../../assets/info.png';

const ImageConfig = () => {


  const [ negativePrompts, setNegativePrompts, height, setHeight, width, setWidth, cfgScale, setCfgScale, /*clipGuidancePreset*/, /*setClipGuidancePreset*/, /*sampler*/, /*setSampler*/, /*samples*/, /*setSamples*/, seed, setSeed, steps, setSteps, stylePreset, setStylePreset, aspectRatio, setAspectRatio, imageStrength, setImageStrength ] = useContext(ImageConfigContext);
  const [infoBlockOpen, setInfoBlockOpen] = useState(null);
  const [newPrompt, setNewPrompt] = useState('');

  const orientations = ['Portrait', 'Landscape'];
  const sizeOptions = [
    { o: 'Portrait', h: 1024, w: 1024, value: '1024x1024', label: '1024x1024' },
    // { o: 'portrait', h: 512, w: 512, value: '512x512', label: '512x512' },
    { o: 'Portrait', h: 1152, w: 896, value: '1152x896', label: '1152x896' },
    { o: 'Portrait', h: 1216, w: 832, value: '1216x832', label: '1216x832' },
    { o: 'Portrait', h: 1344, w: 768, value: '1344x768', label: '1344x768' },
    { o: 'Portrait', h: 1536, w: 640, value: '1536x640', label: '1536x640' },
    { o: 'Landscape', h: 1024, w: 1024, value: '1024x1024', label: '1024x1024' },
    // { o: 'Landscape', h: 512, w: 512, value: '512x512', label: '512x512' },
    { o: 'Landscape', h: 896, w: 1152, value: '896x1152', label: '896x1152' },
    { o: 'Landscape', h: 832, w: 1216, value: '832x1216', label: '832x1216' },
    { o: 'Landscape', h: 768, w: 1344, value: '768x1344', label: '768x1344' },
    { o: 'Landscape', h: 640, w: 1536, value: '640x1536', label: '640x1536' },
  ];
  const aspectRatios = [
    { value: '1:1', label: '1:1'},
    { value: '5:4', label: '5:4'},
    { value: '3:2', label: '3:2'},
    { value: '16:9', label: '16:9'},
    { value: '21:9', label: '21:9'},
    { value: '9:21', label: '9:21'},
    { value: '9:16', label: '9:16'},
    { value: '2:3', label: '2:3'},
    { value: '4:5', label: '4:5'}
  ];
  const styleOptions = [
    { value: '', label: 'None'},
    { value: 'low-poly', label: 'Low Poly' },
    { value: 'analog-film', label: 'Analog Film' },
    { value: '3d-model', label: '3D Model' },
    { value: 'cinematic', label: 'Cinematic' },
    { value: 'anime', label: 'Anime' },
    { value: 'comic-book', label: 'Comic Book' },
    { value: 'neon-punk', label: 'Neon Punk' },
    { value: 'pixel-art', label: 'Pixel Art' },
    { value: 'tile-texture', label: 'Tile Texture' },
    { value: 'photographic', label: 'Photographic' },
    { value: 'fantasy-art', label: 'Fantasy Art' },
    { value: 'digital-art', label: 'Digital Art' },
    { value: 'modeling-compound', label: 'Modeling Compound' },
    { value: 'line-art', label: 'Line Art' },
    { value: 'origami', label: 'Origami' },
    { value: 'isometric', label: 'Isometric' },
    { value: 'enhance', label: 'Enhance' }
  ];

  // const clipOptions = [
  //   { value: '', label: 'None'},
  //   { value: 'FAST_BLUE', label: 'Fast Blue' },
  //   { value: 'FAST_GREEN', label: 'Fast Green' },
  //   { value: 'SIMPLE', label: 'Simple' },
  //   { value: 'SLOW', label: 'Slow' },
  //   { value: 'SLOWER', label: 'Slower' },
  //   { value: 'SLOWEST', label: 'Slowest' }
  // ];

  // const samplerOptions = [
  //   { value: '', label: 'None'},
  //   { value: 'DDIM', label: 'DDIM' },
  //   { value: 'DDPM', label: 'DDPM' },
  //   { value: 'K_DPMPP_2M', label: 'K_DPMPP_2M' },
  //   { value: 'K_DPMPP_2S_ANCESTRAL', label: 'K_DPMPP_2S_ANCESTRAL' },
  //   { value: 'K_DPM_2', label: 'K_DPM_2' },
  //   { value: 'K_DPM_2_ANCESTRAL', label: 'K_DPM_2_ANCESTRAL' },
  //   { value: 'K_EULER', label: 'K_EULER' },
  //   { value: 'K_EULER_ANCESTRAL', label: 'K_EULER_ANCESTRAL' },
  //   { value: 'K_HEUN', label: 'K_HEUN' },
  //   { value: 'K_LMS', label: 'K_LMS' }
  // ];

  const [orientation, setOrientation] = useState((height > width) ? 'Portrait' : 'Landscape');
  const [size, setSize] = useState(sizeOptions[0].value);

  const handleInfoClick = (infoBlockNumber) => {
    setInfoBlockOpen(infoBlockNumber === infoBlockOpen ? null : infoBlockNumber);
  }

  const handleCfgScaleChange = (e) => {
    setCfgScale(parseInt(e.target.value));
  }

  const handleImageStrengthChange = (e) => {
    setImageStrength(e.target.value);
  }

  // const handleClipGuidancePresetChange = (e) => {
  //   setClipGuidancePreset(e.target.value || null);
  // }

  // const handleSamplerChange = (e) => {
  //   setSampler(e.target.value || null);
  // }

  // const handleSamplesChange = (e) => {
  //   setSamples(parseInt(e.target.value));
  // }

  const handleSeedChange = (e) => {
    setSeed(parseInt(e.target.value));
  }

  const handleStepsChange = (e) => {
    setSteps(parseInt(e.target.value));
  }

  const handleStylePresetChange = (e) => {
    setStylePreset(e.target.value || null);
  }

  const handleNewPromptChange = (e) => {
    setNewPrompt(e.target.value);
  };

  const handleAddPrompt = () => {
    const updatedPrompts = [...negativePrompts, newPrompt];
    setNegativePrompts(updatedPrompts);
    setNewPrompt('');
  };

  const handleRemovePrompt = (index) => {
    const updatedPrompts = [...negativePrompts];
    updatedPrompts.splice(index, 1);
    setNegativePrompts(updatedPrompts);
  };

  const handleSizeChange = (e) => {
    setSize(e.target.value);
    const opt = sizeOptions.filter(o => o.value === e.target.value)[0];
    setHeight(opt.h);
    setWidth(opt.w);
  };

  const handleOrientationChange = (e) => {
    setOrientation(e.target.value);
    const curSizeOpt = sizeOptions.filter(o => o.value === size)[0];
    const newSizeOpt = sizeOptions.filter(o => o.h === curSizeOpt.w)[0];
    setSize(newSizeOpt.value);
    setHeight(newSizeOpt.h);
    setWidth(newSizeOpt.w);
  }

  const handleAspectChange = (e) => {
    setAspectRatio(e.target.value || null);
  }

  return (
    <div className="bg-gray-200 min-h-screen p-4">
      <h2 className="font-bold text-2xl mb-4">Model Configurations</h2>
      <p className="text-base mb-6">Adjust the model configurations to control the responsiveness of Skechers GPT and customize your chat experience.</p>
      <form>
        <h2 className="text-xl font-bold mt-8 mr-0 flex item-center">
          Negative Prompt
          <img src={info} alt="Information" className="ml-2 mt-2 w-4 h-4 pointer-cursor" onClick={() => { handleInfoClick(1); }}/>
        </h2>
        <div className="relative flex flex-row grow">
          <div className={`absolute bg-slate-300 h-fit flex flex-col grow mh-2 p-4 z-10 ${infoBlockOpen !== 1 && "hidden"}`}>
            <h3 className="text-s font-bold">Negative Prompt:</h3>
            <p className="my-2">Items or concepts that you don't want the model to generate, such as cartoon or violence.</p>
          </div>
        </div>
        <div className="container">
          <input
            type="text"
            value={newPrompt}
            onChange={handleNewPromptChange}
            className="flex-grow px-3 py-2 mt-4 border border-gray-300 w-48 h-12 text-gray-900 border-solid border-2 border-blue-900"/>
          
          <button type="button" onClick={handleAddPrompt}className="px-4 py-2 h-12 bg-blue-900 text-white">Add</button>
          <div className="mt-4 flex flex-row flex-wrap">
            {negativePrompts.map((word, index) => (
              <div key={index} className="flex items-center mt-2">
                <div className="flex items-center bg-blue-300 w-auto h-10 text-bold border-solid border-2 border-blue-900 px-3 py-2 mr-2">
                  <span>{word}</span>
                  <button type="button" onClick={() => handleRemovePrompt(index)} className="ml-2 px-2 py-1 h-8 bg-blue-300 text-white text-blue-900 rounded-md">
                    X
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="container mt-12">
          <h2 className="text-xl font-bold mb-2 mr-0 flex item-center">
            Response Image
            <img src={info} alt="Information" className="ml-2 mt-2 w-4 h-4 pointer-cursor" onClick={() => { handleInfoClick(3); }}/>
          </h2>
          <div className="relative flex flex-row grow">
            <div className={`absolute bg-slate-300 h-fit flex flex-col grow mh-2 p-4 z-10 ${infoBlockOpen !== 3 && "hidden"}`}>
              <h3 className="text-s font-bold">Aspect Ratio:</h3>
              <p className="my-2">Desired aspect ratio of the response image for the text prompt. (Ignored if there is an input image.)</p>
              <h3 className="text-s font-bold">Image Strength:</h3>
              <p className="my-2">How much influence the input image has on the response image.</p>
              <h3 className="text-s font-bold">Generating with an Input Image:</h3>
              <p className="my-2">Output image will use dimensions of input image.  Though, input image may be resized to meet limitations of SD3 (Width: 640 - 1536 px, Height: 640 - 1536 px). Supported formats: JPEG, PNG, WEBP (SkechAI will automatically try to convert to PNG if not one of these formats).</p>
            </div>
          </div>
          {/* <div className="flex flex-col">
            <label className='flex flex-col font-bold'>
              Orientation
              <div className="flex flex-row ">
                {orientations.map((option) => (
                    <div key={option} className="flex font-normal mr-4">
                      <input
                        type="radio"
                        id={option.value}
                        name="orientation"
                        value={option}
                        checked={orientation === option}
                        onChange={handleOrientationChange}
                        className="mr-2"
                      />
                      <label htmlFor={option}>{option}</label>
                    </div>
                  ))}
              </div>
            </label>
          </div>

          <div className="container mt-4 flex flex-col">
            <label className="font-bold">
              Size
              <div className="flex flex-row">
                <select value={sizeOptions.filter(o => o.h === height && o.w === width)[0].value} className="flex-grow px-3 py-2 mt-4 border border-gray-300 w-48 h-12 text-gray-900 border-solid border-2 border-blue-900" onChange={handleSizeChange}>
                  {sizeOptions.filter(o => o.o === orientation).map((option) => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                  ))}
                </select>
              </div>
            </label>
          </div> */}

          <div className="container mt-4 flex flex-col">
            <label className="font-bold">
              Aspect Ratio
              <div className="flex flex-row">
                <select value={aspectRatio} key={aspectRatio} className="flex-grow px-3 py-2 mt-4 border border-gray-300 w-48 h-12 text-gray-900 border-solid border-2 border-blue-900" onChange={handleAspectChange}>
                  {aspectRatios.map((option) => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                  ))}
                </select>
              </div>
            </label>
          </div>
          <div className="container mt-4 font-bold">
            <label className='flex items-center'>
              Image Strength
              <input type="number" min="0.0" max="1.0" step="0.01" value={imageStrength} onChange={(newValue) => handleImageStrengthChange(newValue)} className="px-2 py-2 text-center w-20 h-8 ml-auto border-solid border-2 border-blue-900"/>
            </label>
            <input type="range" min="0.0" max="1.0" step="0.01" value={imageStrength} onChange={handleImageStrengthChange} className="range range-xs range-config mt-4 w-full bg-gray-300" />
          </div>
        </div>

        <div className="container mt-12">
          <h2 className="text-xl font-bold mb-2 mr-0 flex item-center">
            Advanced Configurations
            <img src={info} alt="Information" className="ml-2 mt-2 w-4 h-4" onClick={() => { handleInfoClick(2); }}/>
          </h2>
          <div className="relative flex flex-row grow">
            <div className={`absolute bg-slate-300 h-fit flex flex-col grow mh-2 p-4 z-10 ${infoBlockOpen !== 2 && "hidden"}`}>
              {/* <h3 className="text-s font-bold">Prompt Strength:</h3>
              <p className="my-2">how much the model should respect your prompt. Lower numbers give the model more freedom to be creative, higher numbers will more strictly adhere to your prompt.</p>
              <h3 className="text-s font-bold">Generation Step:</h3>
              <p className="my-2">Quality improves as the number of step increases. Typically, 20 steps is enough to reach a high-quality, sharp image. Although the image will change subtly at the higher ranges, it will become different but not necessarily of higher quality.</p> */}
              <h3 className="text-s font-bold">Seed:</h3>
              <p className="my-2">The random seed determines the initial noise pattern and, hence, the final image. Using 0 (zero) will result in a random seed being generated. For any other value, using the same seed repeatedly will generate the same image for the same prompt and parameters.</p>
              {/* <h3 className="text-s font-bold">Style Preset:</h3>
              <p className="my-2">An optional set of styles that are understood by the model. You can can leave this unset (none) and just mention a style directly in your prompt as well.</p> */}
              {/* <h3 className="text-s font-bold">Sampler:</h3>
              <p className="my-2">....</p>
              <h3 className="text-s font-bold">Clip Guidance Preset:</h3>
              <p className="my-2">....</p> */}
            </div>
          </div>
        </div>

        {/* <div className="container font-bold">
          <label className='flex items-center'>
            Prompt Strength
            <input type="number" value={cfgScale} onChange={(newValue) => handleCfgScaleChange(newValue)} className="px-2 py-2 text-center w-20 h-8 ml-auto border-solid border-2 border-blue-900"/>
          </label>  
          <input type="range" min="0.0" max="35.0" step="0.1" value={cfgScale} onChange={handleCfgScaleChange} className="range range-xs range-config mt-4 w-full bg-gray-300" />
        </div> */}
        {/* <div className="container mt-4 font-bold">
          <label className='flex items-center'>
            Generation Step
            <input type="number" value={steps} onChange={(handleStepsChange)} className="px-2 py-2 text-center w-20 h-8 ml-auto border-solid border-2 border-blue-900"/>
          </label>  
          <input type="range" min="10" max="50" step="1" value={steps} onChange={handleStepsChange} className="range range-xs range-config mt-4 w-full bg-gray-300" />
        </div> */}

        <div className="container mt-4 font-bold">
          <label className='flex items-center'>
            Seed
            <input type="number" min="0" max="4294967295" step="1" value={seed} onChange={(handleSeedChange)} className="px-2 py-2 text-center w-20 h-8 ml-auto border-solid border-2 border-blue-900"/>
          </label>  
          <input type="range" min="0" max="4294967295" step="1" value={seed} onChange={handleSeedChange} className="range range-xs range-config mt-4 w-full bg-gray-300" />
        </div>

        {/* <div className="container mt-4 flex flex-col">
          <label className="font-bold">
            Style Preset
            <div className="flex flex-row">
              <select value={stylePreset} key={stylePreset} className="flex-grow px-3 py-2 mt-4 border border-gray-300 w-48 h-12 text-gray-900 border-solid border-2 border-blue-900" onChange={handleStylePresetChange}>
                {styleOptions.map((option) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>
          </label>
        </div> */}

        {/* <div className="container mt-4 flex flex-col">
          <label className="font-bold">
            Sampler
            <div className="flex flex-row">
              <select value={sampler} className="flex-grow px-3 py-2 mt-4 border border-gray-300 w-48 h-12 text-gray-900 border-solid border-2 border-blue-900" onChange={handleSamplerChange}>
                {samplerOptions.map((option) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>
          </label>
        </div>

        <div className="container mt-4 flex flex-col">
          <label className="font-bold">
            Clip Guidance Preset
            <div className="flex flex-row">
              <select value={clipGuidancePreset} className="flex-grow px-3 py-2 mt-4 border border-gray-300 w-48 h-12 text-gray-900 border-solid border-2 border-blue-900" onChange={handleClipGuidancePresetChange}>
                {clipOptions.map((option) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>
          </label>
        </div> */}
      </form>
    </div>
  );
};

export default ImageConfig;
