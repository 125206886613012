export const ModelTokenPricing = {
    "source": "https://aws.amazon.com/bedrock/pricing/",
    "source_description": "AWS Bedrock pricing, as of 2025-02-12, for the us-west-2 region.",
    "models": {
        "claude-3-5-sonnet": {
            "input_tokens": 0.003,
            "output_tokens": 0.015,
            "friendly_name": "Claude 3.5 Sonnet"
        },
        "claude-3-sonnet": {
            "input_tokens": 0.003,
            "output_tokens": 0.015,
            "friendly_name": "Claude 3 Sonnet"
        },
        "claude-3-haiku": {
            "input_tokens": 0.0008,
            "output_tokens": 0.004,
            "friendly_name": "Claude 3 Haiku"
        },
        "claude-v2": {
            "input_tokens": 0.0008,
            "output_tokens": 0.024,
            "friendly_name": "Claude V2"
        },

        "stable-image-ultra-v1": { "per_image": 0.14, "friendly_name": "Stable Image Ultra" },
        "sd3-5-large-v1": { "per_image": 0.08, "friendly_name": "Stable Diffusion 3.5 Large" },
        "stable-image-core-v1": { "per_image": 0.04, "friendly_name": "Stable Image Core" },
        "sd3-large-v1": { "per_image": 0.08, "friendly_name": "Stable Diffusion 3 Large" },
        "stable-diffusion-xl-v1": { "per_image": 0.08, "friendly_name": "Stable Diffusion XL" },
    },

    findModel: function (model) {
        // model names are in format: anthropic.claude-3-sonnet-20240229-v1:0
        // strip it to just the model name, e.g. claude-3-sonnet-20240229-v1
        // then find the model in the list that matches (eg, claude-3-sonnet-20240229-v1 contains claude-3-sonnet)
        let modelName = model.split('.')[1].split(':')[0];
        let modelKey = Object.keys(this.models).find(key => modelName.includes(key));
        if (!this.models[modelKey]) {
            return { friendly_name: model };
        } else {
            return this.models[modelKey];
        }
    },
};

