import { React } from 'react';
import { Link, useNavigate } from "react-router-dom";
import skechers_logo_small from '../../assets/skechers_logo_small.png';
import skechai_logo from '../../assets/Dark_BG_SkechAI_logo_sm.png';
import textgen from '../../assets/textgen_intro.png';
import imagegen from '../../assets/imagegen_intro.png';
import * as HeroIcons from '@heroicons/react/24/outline';

/**
 * A component that displays an image.
 *
 * @param {string} text - The source of the image to display.
 * @returns {JSX.Element} - A JSX element representing the image.
 */

const HeroIcon = ({ iconName, ...props }) => {
  const IconComponent = HeroIcons[iconName];

  if (!IconComponent) { // Return a default icon, or null if IconComponent doesn't exist
    return null;
  }

  return <IconComponent {...props} />;
};

const Landing = () => {

  let usecases = JSON.parse(localStorage.getItem('usecases'));

  const navigate = useNavigate();
  return ( 
    <div className="landing-container">
      <header className="landing-header-container">
        <div className="header-logos my-5">
          <img src={skechai_logo} alt="SkechAI Logo" className="w-full max-w-[500px]" />
        </div>
        <Link to="/learn"><button className="learn-more">Learn More</button></Link>
      </header>
      <div className="text-image-container">
        <main className="flex flex-wrap justify-center">

          <div className="landing-button">
            <img src={textgen} alt="Text Generation" className="w-full" />
            <div className="landing-button-sub">
              <h3 className="landing-font">Text Generation</h3>
                <p className="grow text-sm">Welcome to the Text Generation Platform! This platform meets all your text needs, allowing you to create, process, and generate natural language conversations, ideas, and complex information.</p>
              <div className="landing-sub-button">
                <Link to="/text"><button className="launch-button">{usecases ? ('New Chat') : ('Launch')}</button></Link>
              </div>
            </div>
          </div>
          <div className="landing-button">
            <img src={imagegen} alt="Generation Images" className="w-full" />
            <div className="landing-button-sub">
              <h3 className="landing-font">Image Generation</h3>
              <p className="grow text-sm">Welcome to the Image Generation Platform! This platform transforms your text into incredible visuals, perfect for designing concepts and bringing your creative vision to life.</p>
              <div className="landing-sub-button">
                <Link to="/image"><button className="launch-button">Launch</button></Link>
              </div>
            </div>
          </div>

        </main>
      </div>
      <footer className="landing-footer">
        <div className="landing-sub-footer">
          For questions, please reach out to <a href="mailto:skechai@skechers.com" className="underline">skechai@skechers.com</a>
        </div>
        <img src={skechers_logo_small} alt="Skechers Logo" className="landing-skechers-logo" />
      </footer>
    </div>
  );
};

export default Landing;