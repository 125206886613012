import { createContext, useState } from 'react';
import { DefaultTextConfig } from '../components/ModelConfig/DefaultTextConfig';
const TextConfigContext = createContext({});


/**
 * @param {Object} props - The properties passed to the component.
 * @returns {JSX.Element} A ChatContext.Provider element.
 */
const TextConfigContextProvider = (props) => {

  const [temperature, setTemperature] = useState(DefaultTextConfig.temperature);
  const [topP, setTopP] = useState(DefaultTextConfig.top_p);
  const [topK, setTopK] = useState(DefaultTextConfig.top_k);
  const [maxTokens, setMaxtokens] = useState(DefaultTextConfig.max_tokens);
  const [stopSequences, setStopSequences] = useState(DefaultTextConfig.stop_sequences);
  const [template, setTemplate] = useState(DefaultTextConfig.template);
  const [ragInfo, setRagInfo] = useState(null);
  const [queryInfo, setQueryInfo] = useState(null);
  const [isBingSearchEnabled, setIsBingSearchEnabled] = useState(DefaultTextConfig.isBingSearchEnabled);

  // get all config values in a dict
  const getTextConfig = () => {
    return {
      temperature: temperature,
      top_p: topP,
      top_k: topK,
      max_tokens: maxTokens,
      stop_sequences: stopSequences
    };
  };

  // set all config values from preset (from use-case for example) or from a prior session / message
  const setTextConfig = (config, singleValue) => {
    const cfg = config?.kwargs || config;

    if (!singleValue || singleValue === "temperature") {
      setTemperature(cfg.temperature || DefaultTextConfig.temperature);
    }

    if (!singleValue || singleValue === "topP") {
      setTopP(cfg.topP || cfg.top_p || DefaultTextConfig.top_p);
    }

    if (!singleValue || singleValue === "topK") {
      setTopK(cfg.topK || cfg.top_k || DefaultTextConfig.top_k);
    }

    if (!singleValue || singleValue === "maxTokens") {
      setMaxtokens(cfg.maxTokens || cfg.max_tokens || DefaultTextConfig.max_tokens);
    }

    if (!singleValue || singleValue === "stopSequences") {
      setStopSequences(cfg.stopSequences || cfg.stop_sequences || DefaultTextConfig.stop_sequences);
    }
  }

  return (
    <TextConfigContext.Provider value={[
      getTextConfig,
      setTextConfig,

      template, setTemplate,
      ragInfo, setRagInfo,
      queryInfo, setQueryInfo,
      isBingSearchEnabled, setIsBingSearchEnabled,
    ]}>
      {props.children}
    </TextConfigContext.Provider>
  );
};

export { TextConfigContext, TextConfigContextProvider };

